<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <b-button
      class="btn btn-gradient-primary mb-1 mr-50 col-md-2"
      @click="$router.go(-1)"
    >
      <i class="fas fa-arrow-left mr-25" /> {{ $t('returnToList') }}
    </b-button>
    <b-overlay
      :show="showOver"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <b-row class="match-height">
        <b-col md="6">
          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('details') }} {{ items.itemnumber_text }}
              </h3>
            </div>

            <div class="p-2">
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-for="h-paecelCode"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('orderNumber') }} :
                    </template>

                    <p class="mt-50 font-weight-bolder">
                      {{ items.itemnumber_text }}
                    </p>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-paecelCode"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('customerCode') }} :
                    </template>
                    <div class="mt-50 font-weight-bolder">
                      <router-link :to="{ name: 'admin-employee-edit', params: {id: items.uid} }">
                        {{ items.username }}
                      </router-link>
                    </div>
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="items.payment_type.consign_send"
                  md="12"
                >
                  <b-form-group
                    label-for="h-paecelCode"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('key-63') }} :
                    </template>
                    <p
                      class="mt-50 font-weight-bolder"
                    >
                      {{ items.payment_type.type_name }}
                    </p>
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="items.payment_type.consign_send"
                  md="12"
                >
                  <b-form-group
                    label-for="h-paecelCode"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('taobaoID') }} :
                    </template>
                    <p
                      class="mt-50 font-weight-bolder"
                    >
                      {{ items.payment_type.taobao_username }}
                    </p>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="items.payment_type.consign_send"
                  md="12"
                >
                  <b-form-group
                    label-for="h-paecelCode"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('taobaoPassword') }} :
                    </template>
                    <p
                      class="mt-50 font-weight-bolder"
                    >
                      {{ items.payment_type.taobao_password }}
                    </p>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-paecelCode"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('mnemonic') }} :
                    </template>
                    <p class="mt-50 font-weight-bolder">
                      {{ items.keyword ? items.keyword: '-' }}
                    </p>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-keyword"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('mnemonicPhrase') }} :
                    </template>
                    <b-form-textarea
                      id="h-keyword"
                      v-model="items.keyword_admin"
                      type="text"
                      @change="Update()"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>

        <b-col md="6">
          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('Payment') }}
              </h3>
            </div>

            <div class="p-2">
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-for="h-paecelCode"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('orderNumber') }} :
                    </template>

                    <p class="mt-50 font-weight-bolder">
                      {{ items.itemnumber_text }}
                    </p>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-paecelCode"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('amountDue') }} :
                    </template>
                    <div class="mt-50 font-weight-bolder">
                      <!-- <router-link :to="{ name: 'admin-traking' }"> -->
                      {{ Commas(items.pay_products) }} ฿
                    <!-- </router-link> -->
                    </div>
                  </b-form-group>
                </b-col>

                <!-- <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols="3"
          label-cols-lg="3"
          label-cols-md="3"
                >
                  <template v-slot:label>
                    ใช้ยอดเงินหยวน :
                  </template>
                  <p class="mt-50 font-weight-bolder">
                    ¥ {{ items.apply_yuan ? Commas(items.apply_yuan): 0 }}
                  </p>
                </b-form-group>
              </b-col> -->

                <b-col md="12">
                  <b-form-group
                    label-for="h-paecelCode"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('paymentType') }} :
                    </template>
                    <p class="mt-50 mb-0 badge badge-light-secondary">
                      {{ $t('useSystemBalance') }}
                    </p>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-paecelCode"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('remarks') }} :
                    </template>
                    <p class="mt-50 font-weight-bolder">
                      -
                    </p>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>

        <b-col md="12">
          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('listProducts') }}
              </h3>
            </div>

            <div class="p-2 table-container">
              <table class="table table-respone">
                <colgroup>
                  <col style="width: 5%">
                  <col style="width: 35%">
                  <col style="width: 10%">
                  <col style="width: 10%">
                  <col style="width: 10%">
                  <col style="width: 10%">
                </colgroup>
                <thead>
                  <tr>
                    <th>{{ $t('sequence') }}</th>
                    <th class="small remark">
                      {{ $t('orderList') }}
                    </th>
                    <th style="text-align: center !important">
                      {{ $t('key-64') }}
                    </th>
                    <th
                      class="date"
                      style="text-align: center !important"
                    >
                      {{ $t('transactionDate') }}
                    </th>
                    <th style="text-align: center !important">
                      {{ $t('price') }}
                    </th>
                    <th style="text-align: center !important">
                      {{ $t('manage') }}
                    </th>
                  </tr>
                </thead>
                <tbody
                  v-for="(item,index) in items.products"
                  :key="index"
                  class="ant-table-tbody"
                >
                  <tr class="ant-table-row ant-table-row-level-0">
                    <td>{{ index+1 }}</td>

                    <td>
                      <span>{{ item.order_id }}</span>
                    </td>

                    <td style="text-align: center">
                      <router-link
                        :to="{name : 'admin-employee-edit', params: {id: items.uid}}"
                      >
                        {{ items.username }}
                      </router-link>
                    </td>

                    <td
                      class="date"
                      style="text-align: center"
                    >
                      <div>{{ item.date_approve ? time(item.date_approve): '-' }}</div>
                    <!-- <small>17:10:51</small> -->
                    </td>

                    <td style="text-align: center">
                      ¥ {{ Commas(item.price) }}
                    </td>

                    <td style="text-align: center ">
                      <div
                        v-if="item.status == 1"
                        class="row"
                      >
                        <div class="col-5">
                          <button
                            class="btn btn-danger "
                            style="font-size: 10px;"
                            @click="canceldata(item)"
                          >{{ $t('cancel') }}</button>
                        </div>
                        <div class="col-7">
                          <button
                            class="btn btn-success"
                            style="font-size: 10px;"
                            @click="Submitpay(item)"
                          >{{ $t('key-30') }}</button>
                        </div>
                      </div>
                      <span
                        v-if="item.status == 2"
                        class=" badge badge-light-success col-md-8"
                      >{{ $t('operationCompleted') }}</span>
                      <span
                        v-if="item.status == 0"
                        class=" badge badge-light-secondary col-md-6 text-black"
                        style="font-size: 12px;"
                      >{{ $t('cancelled') }}</span> <span
                        v-if="item.status == 0"
                        class="text-danger"
                      >* {{ item.remark_cancel ? `${item.remark_cancel} ,` : '' }} {{ item.cancel_by ? `${ $t('canceledBy')} ${item.cancel_by}`: ''}}</span>
                    <!-- style="font-size: 10px;" -->
                    <!-- style="font-size: 10px;" -->
                    <!-- <span>ยกเลิกแล้ว</span> <br>
                    <span
                      class="text-danger"
                    >*โดย admin01</span> -->
                    </td>
                  </tr>
                </tbody>
                <tfoot class="ant-table-summary">
                  <tr>
                    <th
                      colspan="4"
                      class="text-right"
                    >
                      {{ $t('yuanAmount') }}
                    </th>
                    <td
                      colspan="2"
                      class="text-right"
                    >
                      <span class="ant-typography">{{ Commas(items.products.reduce((total,acc) => total+ acc.price,0)) }} x {{ Commas(items.rate) }} = {{ Commas(items.products.reduce((total,acc) => total+ acc.price,0)*items.rate) }} ฿</span>
                    </td>
                  </tr>
                  <!-- <tr v-if="items.apply_yuan">
                  <th
                    colspan="4"
                    class="text-right"
                  >
                    ยอดเงินหยวนในระบบ
                  </th>
                  <td
                    colspan="2"
                    class="text-right"
                  >
                    <span class="ant-typography">{{ Commas(items.apply_yuan) }} x {{ Commas(items.rate) }} = -{{ Commas(items.apply_yuan*items.rate) }} ฿</span>
                  </td>
                </tr> -->
                  <tr>
                    <th
                      colspan="4"
                      class="text-right"
                    >
                      {{ $t('key-65') }}
                    </th>
                    <td
                      colspan="2"
                      class="text-right"
                    >
                      <span class="ant-typography">{{ Commas(items.products.length) }} x {{ Commas(items.rate_service) }} = {{ Commas(items.products.length*items.rate_service) }} ฿</span>
                    </td>
                  </tr>
                  <tr>
                    <th
                      colspan="4"
                      class="text-right"
                    >
                      {{ $t('key-66') }}
                    </th>
                    <td
                      colspan="2"
                      class="text-right"
                    >
                      <span class="ant-typography">{{ Commas(items.pay_products) }} ฿</span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </b-col>

        <b-col md="6">
          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('relatedAmountList') }}
              </h3>
            </div>

            <div class="pl-2 pr-2">
              <b-table
                responsive="sm"
                :fields="fieldsRelevant"
                :items="Transactions"
                show-empty
              >
                <template #empty="">
                  <b-table-empty-slot>
                    <div class="text-center my-2">
                      <img
                        src="@/assets/images/anan-img/svg/doc_empty.svg"
                        alt="empty"
                        height="60px"
                      >
                      <p class="text-muted">
                        No Data
                      </p>
                    </div>
                  </b-table-empty-slot>
                </template>

                <template #cell(transactionDate)="data">
                  {{ time(data.item.created_at) }}
                  <!-- <b-link :to="{name: 'admin-user-type-edit', params: {id: data.item.id}}">
                BLA-CN-{{ items.length - (perPage * (currentPage - 1) + (data.index + 1)) + 1 }}
              </b-link> -->
                </template>
                <template #cell(amount)="data">
                  <!-- <span
                  v-if="data.item.itemnumber_text == `BLA-CN-${data.item.itemnumber}`"
                  :style="getAmountStyle(data.item.type)"
                >
                  {{ getAmountPrefix(data.item.type) }} {{ Commas(data.item.amount ) }} ¥
                </span> -->
                  <span
                    :style="getAmountStyle(data.item.type, data.item.type_amount)"
                  >{{ getAmountPrefix(data.item.type, data.item.type_amount) }} {{ Commas(data.item.amount) }} ฿</span>
                </template>
              </b-table>
            </div>
          </div>
        </b-col>

        <b-modal
        id="modal-cliam"
        :title="$t('remarks')"
        :ok-title="$t('save')"
        :cancel-title="$t('cancel')"
        @ok="submitAddNew()"
      >

        <b-form-group>
          <!-- <validation-provider
            #default="{ errors }"
            name="detail"
            rules="required"
          > -->
            <label for="claim_detail">{{ $t('details') }} :</label>
            <b-form-textarea
              id="claim_detail"
              v-model="remark_cancel"
              :placeholder="$t('remarks')"
              
            />
            <!-- :state="errors.length > 0 ? false:null" -->
            <!-- <span class="text-danger">{{ errors[0] ? $t('key-24'): '' }}</span> -->
          <!-- </validation-provider> -->
        </b-form-group>

      </b-modal>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
  BButton,
  BTable,
  BOverlay,
} from 'bootstrap-vue'
import moment from 'moment-timezone'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    BButton,
    BTable,
    BOverlay,
  },
  data() {
    return {
      isActive: true,
      file: null,
      parcelCode: null,
      remark: null,
      Transaction: [],
      ID: this.$route.params.id,
      items: [],
      keyword_admin: null,
      Transactions: [],
      showOver: false,
      cancel_details: null,
      remark_cancel: null,
    }
  },
  computed: {
    fieldsRelevant() {
      return [
        {
          key: 'transactionDate', label: this.$t('transactionDate'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'detail', label: this.$t('details'), sortable: false, thStyle: { width: '45%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'amount', label: this.$t('quantity'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  mounted() {
    this.getData()
    this.getTransactions()
    // await this.getTransactionsChina()
  },
  methods: {
    time(data) {
      return moment(data).format('YYYY-MM-DD HH:mm:ss')
    },
    async getData() {
      this.showOver = true
      try {
        const params = {
          ID: this.ID,

        }
        const { data: res } = await this.$http.get('/Payforchina/getPaymentOne', { params })
        this.items = res
        this.showOver = false
        // console.log(res)
      } catch (err) {
        console.log(err)
        this.SwalError(err)
        this.showOver = false
      }
    },
    async getTransactions() {
      try {
        const params = {
          ref_id: this.ID,
        }
        const { data: res } = await this.$http.get('/transactions/show/ref', { params })

        if (res.success) {
          this.Transactions = res.data
          // this.getTransactionsChina(res.data)
        }
      } catch (err) {
        console.log(err)
        this.SwalError(err)
      }
    },
    // getTransactionsChina(data) {
    //   try {
    //     const params = {
    //       ref_id: this.ID,
    //     }
    //     this.$http.get('/transactions/show/refChina', { params })
    //       .then(success => {
    //         if (success.data.success) {
    //           this.Transactions = data
    //           success.data.data.forEach(ele => {
    //             this.Transactions.push(ele)
    //           })
    //         } else {
    //           this.Transactions = data
    //         }
    //       })
    //     // console.log(res)
    //     // if (res.success) {
    //     //   res.data.forEach(ele => {
    //     //     this.Transactions.push(ele)
    //     //     // console.log(ele)
    //     //   })
    //     //   console.log(this.Transactions)
    //     //           this.Transactions.push(res.data)
    //     // console.log(this.Transactions)
    //     // }
    //   } catch (err) {
    //     console.log(err)
    //     this.SwalError(err)
    //   }
    // },
    //  const { data: resnew } = await this.$http.get('/transactions/show/refChina', { params })
    canceldata(data) {
      this.$bvModal.msgBoxConfirm(`ต้องการยกเลิกรายการ ยอด ¥ ${data.price} ?`, {
        title: 'ยืนยันข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(value => {
        if (value) {
          this.cancel_details = {
            // eslint-disable-next-line no-underscore-dangle
            id: data._id,
            // eslint-disable-next-line no-underscore-dangle
            ID: this.items._id,
            status: 0,
            price: data.price,
            uid: this.items.uid,
          }
          this.$bvModal.show('modal-cliam')
          // this.$http.post('/Payforchina/Cancel', obj)
          //   .then(() => {
          //     this.getData()
          //     this.getTransactions()
          //     this.Success('ยกเลิกเรียบร้อยแล้ว')
          //   })
          //   .catch(err => {
          //     console.log(err)
          //     this.SwalError(err)
          //   })
        } else {
          this.cancel_details = null
        }
      })
    },
    submitAddNew() {
      if (this.cancel_details) {
        this.cancel_details.remark_cancel = this.remark_cancel
        this.$http.post('/Payforchina/Cancel', this.cancel_details)
            .then(() => {
              this.getData()
              this.getTransactions()
              this.Success('ยกเลิกเรียบร้อยแล้ว')
              this.remark_cancel = null
              this.cancel_details = null
            })
            .catch(err => {
              this.SwalError(err)
              this.remark_cancel = null
              this.cancel_details = null
            })
      }
    },
    Submitpay(data) {
      this.$bvModal.msgBoxConfirm(`ต้องการอนุมัติจ่ายแล้ว ยอด ¥ ${data.price} ?`, {
        title: 'ยืนยันข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(value => {
        if (value) {
          const obj = {
            // eslint-disable-next-line no-underscore-dangle
            id: data._id,
            // eslint-disable-next-line no-underscore-dangle
            ID: this.items._id,
            status: 2,
            price: data.price,
          }
          this.$http.post('/Payforchina/UpdateData', obj)
            .then(() => {
              this.getData()
              this.getTransactions()
              this.Success('อนุมัติเรียบร้อยแล้ว')
            })
            .catch(err => {
              console.log(err)
              this.SwalError(err)
            })
        }
      })
    },
    Update() {
      const obj = {
        ID: this.ID,
        keyword_admin: this.items.keyword_admin,
      }
      this.$http.post('/Payforchina/Update', obj)
        .then(() => {
          this.getData()
          this.Success('บันทึกเรียบร้อยแล้ว')
          this.getTransactions()
        })
        .catch(err => {
          console.log(err)
          this.SwalError(err)
        })
    },
    // Submit() {
    //   // eslint-disable-next-line no-alert
    //   alert('Submit')
    // },
    getAmountStyle(amount, typeAmount) {
      if (amount === 21) {
          if (typeAmount) {
            return { color: 'green' }
          } else {
            return { color: 'red' }
          }
        } else {
            if ([1, 8, 11, 12, 17, 16].includes(amount)) {
              return { color: 'green' }
            }
            if ([2, 3, 4, 5, 6, 7, 13, 15, 18, 10, 9, 20].includes(amount)) {
              return { color: 'red' }
            }
        }
    },
    getAmountPrefix(value, typeAmount) {
      if (value === 21) {
          if (typeAmount) {
            return '+'
          } else {
            return '-'
          }
        } else {
            if ([1, 8, 11, 12, 17, 16].includes(value)) {
              return '+'
            }
            if ([2, 3, 4, 5, 6, 7, 13, 15, 18, 10, 9, 20].includes(value)) {
                return '-'
            }
        }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    showAdd() {
      this.$bvModal.show('add-addr')
    },
    showModal() {
      this.name = null
      this.phone = null
      this.address = null
      this.province = null
      this.district = null
      this.subdistrict = null
      this.postalcode = null
      this.$bvModal.show('modal-1')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
